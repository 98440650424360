import React from "react"
import PortableText from "react-portable-text"
import Image from "gatsby-plugin-sanity-image"

const PortableTextComp = ({ textData }) => {
  const serializer = {
    marks: { strong: props => <strong className="text-red-900" {...props} /> },
    button: props => (
      <button className="py-10">
        <a
          className="bg-red-900 p-5 rounded-xl text-white font-sans uppercase hover:bg-red-800 shadow"
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="concert Olga Amosova"
        >
          {props.children}
        </a>
      </button>
    ),
    link: props => {
      const { href, children } = props
      return (
        <>
          <a
            href={href}
            className="text-red-900 font-semibold underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        </>
      )
    },
    image: props => {
      return (
        <Image
          // pass asset, hotspot, and crop fields
          {...props}
          // tell Sanity how large to make the image (does not set any CSS)
          width={300}
          height={200}
          alt={props.alt || "Ольга Амосова"}
          className="rounded"
          // style it how you want it
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )
    },
  }
  return (
    <PortableText
      className="pt-3 font-sans max-h-full text-lg"
      content={textData}
      serializers={serializer}
    />
  )
}

export default PortableTextComp
